import * as Sentry from '@sentry/vue';

export const initSentry = (app) => {
  Sentry.init({
    app,
    dsn: 'https://c742ceb8bd6573ddec7a2bda38ae4686@o4506157196902400.ingest.sentry.io/4506157231046656',
    tracesSampleRate: 1.0,
    profilesSampleRate: 0.25,
    tracePropagationTargets: ['localhost', /^https:\/\/api\.icerik\.com\/api/],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
  });
};